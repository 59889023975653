<template>
  <div class="Text-Wall part">
    <div class="constrain">
      <div class="row-12">

        <div class="col-12 lg:offset-2 lg:col-8" v-html="payload.text" />

      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: { payload: { type: Object, default: Object } },
};
</script>

<style scoped lang="scss">

</style>
